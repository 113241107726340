export default {
  blue: '#2b8cbe',
  dark_gray_text: '#454d4f',
  dark_pink_highlight: '#d94262',
  light_pink_highlight: '#fbecef',
  dark_teal_heading: '#205e74',
  dark_yellow_highlight: '#e39e25',
  dark_blue: '#08589e',
  green: '#a8ddb5',
  light_blue: '#4eb3d3',
  light_blue_bg: '#eef4fb',
  light_blue_header: '#e9f5ff',
  light_teal: '#dbe9e9',
  light_teal_highlight: '#e8eef1',
  teal: '#008080',
  pale_teal: '#ddeded',
  white_smoke: '#E8e8e8',
  pale_tint: '#F2F9F8',
  silver: '#C0C0C0',
  light_pink: '#fbf5f6',
  gray_header: '#afb3b3'
};
