const plotColors = {
    gradients: [
        ['#4b7916', "#6BAC20", "#9EDF53" ,"#C5EC98"],
        ['#d95f02', "#FD9749", "#FEBA86", "#FEDCC2"],
        ['#5A54A0', "#7F79B9", "#A6A1CE", "#CDCAE3"],
        ['#DC187E', "#ED5AA6", "#F17EBA", "#F7B6D7"],
        ['#0a7bcc', "#1FB0FF", "#85D4FF", "#C2EAFF"],
        ['#CA9502', "#FDC221", "#FED872", "#FEE9AE"],
        ['#378169', "#54B697", "#8DCEB9", "#C6E7DC"],
        ['#525252', "#7A7A7A", "#A3A3A3", "#CCCCCC"],
        ['#a11235', '#cf1744', "#e9305d", "#ee5e81"],
        ['#2341ab', '#2e51d4', '#5875dc', '#8298e5'],
        ['#4d2700', '#673300', '#9a4d00', '#cd6600'],
        ['#0db7dd', '#2acff3', '#5ad9f5', '#8ae4f8'],
        ['#dd320d', '#f34e2a', '#f5765a', '#f89e8a'],
        ['#343400', '#676700', '#9a9a00', '#cdcd00'],
        ['#4d004d', '#670067', '#9a009a', '#cd00cd'],
        ['#9ed512', '#b7ed2e', '#c7f15c', '#d7f58b'],
        ['#f577a5', '#f68fb5', '#f8a6c4', '#fabed4'],
        ['#2e655f', '#3e8880', '#4eaaa0', '#6fbdb4'],
        ['#652e34', '#883e46', '#aa4e58', '#bd6f77'],
        ['#b372ff', '#c18bff', '#cea5ff', '#dcbeff']
    ],
    default: ['#525252', "#7A7A7A", "#A3A3A3", "#CCCCCC"],
    unique24: ['#9acd32', '#000080', '#483d8b', '#808000', '#006400', '#8b4513', '#2f4f4f', '#dc143c', '#00ff7f', '#8a2be2', '#00ff00', '#ffff00', '#ffa500', '#ff4500', '#48d1cc', '#8b008b', '#ee82ee', '#ffa07a', '#ff1493', '#f0e68c', '#db7093', '#1e90ff', '#ff00ff', '#00bfff'],
    tissues: [
        '#CAD2C5', '#84a98c', '#52796F', '#354F52', '#2F3E46', '#284B63',
        '#2F4858', '#1B263B', '#415A77', '#778DA9', '#E0E1DD', '#6F523B',
    ]
};

export default plotColors;